export const homeData = {
  banners: [
    {
      cover: require('../../assets/images/banner/banner1.png'),
    },
  ],
  content: {
    group1: {
      h: [
        '最新最全的表情包',
        '聊天更有趣',
      ],
      p: [
        '自制表情包用于打字聊天，斗图就要独一无二',
        '表情推荐，打字实时联想你想要的那一款',
        '查看附近热门表情，熟人好图不错过',
      ]
    },
    group2: {
      h: [
        '智能键盘',
        '联想输入更高效',
      ],
      p: [
        '拼音、手写、语音等更多输入方式',
        '智能联想输入，更懂你的表达',
        '优质的键盘布局，输入体验更高效',
      ]
    }
  }
};
