import React, { useEffect, useState } from 'react'
import Pc from './modules/pc/index'
import Mobile from './modules/mobile/index'
import { isM } from '../../utils/index'
import 'swiper/css/swiper.min.css'

function HomePage(props) {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isM());
    setLoading(false);
    window.addEventListener('resize', () => {
      setIsMobile(isM())
    })
  } , []);
  return (
    <>
      {
        !loading
        ? isMobile
          ? <Mobile /> : <Pc />
        : null
      }
    </>
  )
}

export default React.memo(HomePage)
