import styled from "styled-components";
import flex from "../../assets/style/flex";

export const Container = styled.div`
  box-sizing: border-box;
  padding-top: 50px;
  height: 402px;
  background-color: #fff;
  color: #3D3D3D;
  h3{
    margin-bottom: 42px;
    width: 100%;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 20px;
  }
`;
export const Wrap = styled.div`
  ${flex['flex-r-c']}
  align-items: flex-start;
  font-size: 16px;
  .wrap{
    width: 190px;
    ${flex['flex-c-fs']}
    align-items: flex-start;
    &.wx{
      position: relative;
      margin-right: 53px;
      width: 240px;
      &::after{
        content: '';
        position: absolute;
        right: -45px;
        bottom: 0;
        width: 1px;
        height: 170px;
        background-color: #D8D8D8;
      }
    }
    &.qq{
      margin-left: 28px;
      width: 240px;
    }
    img{
      margin-bottom: 6px;
      width: 72px;
      height: 72px;
    }
    h4{
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-weight: bold;
    }
    ul{
      width: 100%;
      li{
        height: 34px;
        line-height: 34px;
        cursor: pointer;
      }
    }
  }
`;
