import styled, { css } from 'styled-components'
import flex from '../../assets/style/flex'

const normalStyle = `
  height: 80px;
  font-size: 24px;
  color: #fff;
  border-radius: 6px;
  width: 320px;
  transition: .1s;
  &:hover{
    opacity: 0.8;
  }
`;
const miniNormalStyle = `
  height: 50px;
  font-size: 16px;
  color: #fff;
  border-radius: 6px;
  width: 180px;
  transition: .1s;
  &:hover{
    opacity: 0.8;
  }
`;
const mobileStyle = `
  height: 0.96rem;
  font-size: 0.4rem;
  color: #000;
  border-radius: 0.48rem;
  width: 5.7rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.1);
  transition: .1s;
  &:hover{
    opacity: 0.8;
  }
`;
const miniMobileStyle = `
  width: 1.78rem;
  height: 0.6rem;
  font-size: 0.28rem;
  color: #fff;
  border-radius: 0.08rem;
  transition: .1s;
  &:hover{
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  ${flex['flex-r-c']}
  background: ${props => props.color};
  cursor: pointer;
  ${props => {
    if (props.type === 'pc') {
      if (props.size === 'normal') {
        return normalStyle;
      } else {
        return miniNormalStyle
      }
    } else {
      if (props.size === 'normal') {
        return mobileStyle;
      } else {
        return miniMobileStyle;
      }
    }
  }}
`;

export const Prefix = styled.div`
  ${props => {
    if (props.type === 'pc') {
      return css`
        margin-bottom: 4px;
        margin-right: 6px;
        width: 34px;
        height: 34px;
      `
    } else {
      return css`
        margin-right: 0.16rem;
        width: 0.56rem;
        height: 0.56rem;
      `
    }
  }}
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
`;

