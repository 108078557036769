import React, {useEffect, useState} from 'react'
import { PageContainer1, Content } from './style'
import Button from '../../../../../components/button/index'
import Toast from '../../../../../components/toast/index'
import { isIos, downloadApp } from '../../../../../utils/index'
import { AndroidDownLoadUrl, IosDownLoadUrl } from '../../../../../config/index'

function Page1(props) {
  const [btnText, setBtnText] = useState('');
  const [ios, setIOS] = useState(false);
  useEffect(() => {
    if (isIos()) {
      setIOS(true);
      setBtnText('App Store 下载')
    } else {
      setIOS(false);
      setBtnText('立即下载')
    }
  }, []);

  const download = async () => {
    try {
      downloadApp({
        androidDownloadUrl: AndroidDownLoadUrl,
        iosDownLoadUrl: IosDownLoadUrl,
      });
    } catch (e) {
      Toast.error('请在浏览器中打开')
    }
  };

  return (
    <PageContainer1>
      <Content>
        <img className="logo" src={require('../../../../../assets/images/mobile/logo.png')} />
        <img className="phone" src={require('../../../../../assets/images/mobile/phone.png')} />
        <div className="btn-wrap">
          <Button type="mobile" prefix={ios ? require('../../../../../assets/images/logos/ios_black.png') : null} color="#FFCC08" handleClick={download}>{btnText}</Button>
        </div>
      </Content>
    </PageContainer1>
  )
}

export default React.memo(Page1)
