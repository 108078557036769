import styled, {css} from 'styled-components'
import flex from '../../assets/style/flex'

const normalStyle = css`
  position: relative;
  width: 666px;
  .slide{
    ${flex['flex-r-c']}
    height: 430px;
    img{
      width: 100%;
      transition: .2s;
      box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0);
    }
    &.swiper-slide-active{
      img{
        box-shadow: 0 10px 20px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
const miniStyle = css`
  position: relative;
  width: 6rem;
  .slide{
    ${flex['flex-r-c']}
    height: 4rem;
    img{
      width: 100%;
      transition: .2s;
      box-shadow: 0 0.1rem 0.2rem 0.05rem rgba(0, 0, 0, 0);
    }
    &.swiper-slide-active{
      img{
        box-shadow: 0 0.1rem 0.2rem 0.01rem rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

export const Container = styled.div`
  ${props => {
    if (props.size === 'mini') {
      return miniStyle
    } else {
      return normalStyle
    }  
  }}
`;
