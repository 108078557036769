import React from 'react'
import PropTypes from 'prop-types'
import { Container, Prefix } from './style'

function Button(props) {
  const { color, width, type, size, prefix } = props;
  const { handleClick } = props;
  return (
    <Container width={width} color={color} type={type} size={size} onClick={handleClick}>
      {
        prefix ? <Prefix type={type} icon={prefix} /> : null
      }
      <p>
        {props.children}
      </p>
    </Container>
  )
}

Button.defaultProps = {
  handleClick: () => {},
  color: '#25AF61',
  width: '100%',
  type: 'pc',
  size: 'normal'
};

Button.propTypes = {
  handleClick: PropTypes.func,
  color: PropTypes.string,
  width: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string
};

export default React.memo(Button)
