export const footData = [
  {
    title: '关于我们',
    icon: require('./icon/base.png'),
    class: 'base',
    list: [
      {
        name: '比格基地',
        href: '//www.bigbaser.com',
      }
    ],
  },
  {
    title: '联系我们',
    icon: require('./icon/sina.png'),
    class: 'sina',
    list: [
      {
        name: '微博: 键多多输入法',
        href: '',
      },
      {
        name: '微信: 键多多输入法APP',
        href: '',
      }
    ],
  },
  {
    title: '商务合作',
    icon: require('./icon/wx.png'),
    class: 'wx',
    list: [
      {
        name: '邮箱：jddbd@innotechx.com',
        href: '',
      },
      {
        name: '皮肤表情投稿',
        href: '',
      }
    ],
  },
  {
    title: '问题反馈',
    icon: require('./icon/qq.png'),
    class: 'qq',
    list: [
      {
        name: '键多多官方QQ群：755404139',
        href: '',
      },
      {
        name: '沪ICP备18045978号-4',
        href: 'http://beian.miit.gov.cn/',
        target: '_blank'
      },
      {
        name: '沪网文（2019）2608-184 号',
        href: '',
      }
    ],
  },
];
