import styled from "styled-components";
// 扩大可点击区域
const extendClick = () => {
  return `
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -10px; bottom: -10px; left: -10px; right: -10px;
    };
  `
};

const noWrap = () => {
  return `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `
};

const maxWidth = () => {
  return `
    box-sizing: border-box;
    margin: 0 auto;
    width: 1440px;
  `
};

// 点状背景
const dotBg = () => {
  return `
    background-image:
    radial-gradient(rgba(0, 0, 0, 0.1) 20%, transparent 0),
    radial-gradient(rgba(0, 0, 0, 0.1) 20%, transparent 0);
    background-size:10px 10px;
    background-position: 0 0, 5px 5px;
  `
};

const border = 'border: 0.05rem solid #000;';

const fontOutLine = () => {
  return `
    color: #fff;text-shadow: #000 1px 0 0,#000 0 1px 0,#000 -1px 0 0,#000 0 -1px 0,#000 1px 1px 0, #000 -1px -1px 0, #000 -1px 1px 0, #000 1px -1px 0;
  `
};
export default {
  'theme-color': '#5F11D6',
  'theme-color-shadow': 'rgba (212, 68, 57, .5)',
  'font-color-light': '#f1f1f1',
  'font-color-desc': '#2E3030',
  'font-color-desc-v2': '#bba8a8',
  'font-size-ss': '10px',
  'font-size-s': '12px',
  'font-size-m': '14px',
  'font-size-l': '16px',
  'font-size-ll': '18px',
  'border-color': '#e4e4e4',
  'background-color': '#f2f3f4',
  'background-color-shadow': 'rgba (0, 0, 0, 0.3)',
  'highlight-background-color': '#fff',
  extendClick,
  noWrap,
  dotBg,
  fontOutLine,
  border,
  maxWidth
}
