import styled, { keyframes } from 'styled-components'
import flex from '../../../../assets/style/flex'
import style from '../../../../assets/style/global-style'

export const Container = styled.div`
  min-width: 1440px;
`;
export const Content = styled.div`
  background-image: linear-gradient(#fff, #F6F6F6);
`;
export const InnerWrap = styled.div`
  ${style.maxWidth}
  padding: 0 164px;
  .wrap{
    ${flex['flex-r-sb']}
    width: 100%;
    height: 680px;
  }
`;
export const Info = styled.div`
  h2{
    height: 60px;
    line-height: 60px;
    font-size: 40px;
    color: #000;
    text-align: left;
  }
  .white-block{
    height: 28px;
  }
  p{
    height: 21px;
    line-height: 21px;
    font-size: 14px;
    color: #6D7278;
  }
`;
export const BtnWrap = styled.div`
  box-sizing: border-box;
  ${flex['flex-r-sb']}
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 56px;
  width: 700px;
  height: 367px;
`;

const arrowAnimate = keyframes`
  0%{
    opacity: 1;
    transform: translateY(0);
  }
  100%{
    opacity: 0;
    transform: translateY(20px);
  }
`

export const ArrowWrap = styled.div`
  position: fixed;
  bottom: 80px;
  left: 50%;
  margin-left: -35px;
  width: 70px;
  height: 20px;
  z-index: 20;
  img{
    width: 100%;
    height: 100%;
    animation: ${arrowAnimate} 1.2s ease-out infinite;
  }
`;
