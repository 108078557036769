import React from 'react'
import PicGroup from '../../../../../components/picGroup2/index'
import { PageContainer3, Title, Group2Wrap, Info } from './style'
import { homeData } from '../../../data'

function Page3(props) {
  const { active } = props;
  return (
    <PageContainer3>
      <Title>
        { homeData.content.group2.h.map((item, i) => <h2 style={{animationDelay: `0.${i}s`}} className={`animated ${active ? 'fadeInDown' : ''}`} key={i}>{item}</h2>) }
      </Title>
      <Group2Wrap>
        <PicGroup size="mini" />
      </Group2Wrap>
      <Info>
        { homeData.content.group2.p.map((item, i) => <p style={{animationDelay: `0.${i}s`}} className={`animated ${active ? 'fadeInUp' : ''}`} key={i}>{item}</p>) }
      </Info>
    </PageContainer3>
  )
}

export default React.memo(Page3)
