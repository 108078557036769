import React from 'react'
import { Container, Wrap } from './style'
import { footData } from './data'

function Foot(props) {

  const jumpHref = (item) => {
    const { target, href } = item
    if (target) {
      window.open(href, target)
    } else {
      window.location.href = href
    }
  }

  return (
    <Container>
      <h3>一 键多多输入法 一</h3>
      <Wrap>
        {
          footData.map((item, i) => {
            return (
              <div key={i} className={`wrap ${item.class}`}>
                <img src={item.icon} />
                <h4>{item.title}</h4>
                <ul>
                  {
                    item.list.map((item, i) => <li key={i} onClick={() => jumpHref(item)}>{item.name}</li>)
                  }
                </ul>
              </div>
            )
          })
        }
      </Wrap>
    </Container>
  )
}

export default React.memo(Foot)
