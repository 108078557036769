const flex        = 'display: flex;';
const direction_r = 'flex-direction: row;';
const direction_c = 'flex-direction: column;';
const fs          = 'justify-content: flex-start;';
const fe          = 'justify-content: flex-end;';
const sa          = 'justify-content: space-around;';
const c           = 'justify-content: center;';
const sb          = 'justify-content: space-between;';
const align_c     = 'align-items: center;';

const rowSpaceBetween    = () => `${flex}${direction_r}${sb}${align_c}`;
const rowSpaceAround     = () => `${flex}${direction_r}${sa}${align_c}`;
const rowCenter          = () => `${flex}${direction_r}${c}${align_c}`;
const rowFlexStart       = () => `${flex}${direction_r}${fs}${align_c}`;
const rowFlexEnd         = () => `${flex}${direction_r}${fe}${align_c}`;
const columnSpaceBetween = () => `${flex}${direction_c}${sb}${align_c}`;
const columnSpaceAround  = () => `${flex}${direction_c}${sa}${align_c}`;
const columnCenter       = () => `${flex}${direction_c}${c}${align_c}`;
const columnFlexStart    = () => `${flex}${direction_c}${fs}${align_c}`;
const columnFlexEnd      = () => `${flex}${direction_c}${fe}${align_c}`;

export default {
  'flex-r-sb': rowSpaceBetween,
  'flex-r-sa': rowSpaceAround,
  'flex-r-c' : rowCenter,
  'flex-r-fs': rowFlexStart,
  'flex-r-fe': rowFlexEnd,
  'flex-c-sb': columnSpaceBetween,
  'flex-c-sa': columnSpaceAround,
  'flex-c-c' : columnCenter,
  'flex-c-fs': columnFlexStart,
  'flex-c-fe': columnFlexEnd,
}
