import styled from 'styled-components'
import flex from '../../../../../assets/style/flex'

const page = `
  width: 100%;
  height: 100%;
`;

export const PageContainer1 = styled.div`
  ${flex['flex-c-c']}
  ${page}
  background-color: #24AF61;
  background-image: url(${require('../../../../../assets/images/mobile/bg.png')});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% auto;
  position: relative;
  
`;

export const Content = styled.div`
  position: relative;
  height: 10.2rem;
  width: 6.8rem;
  .logo{
    position: absolute;
    top: 0;
    left: 50%;
    width: 5.08rem;
    height: 1.96rem;
    transform: translate(-50%, 0);
  }
  .phone{
    position: absolute;
    left: 50%;
    top: 1.6rem;
    transform: translate(-50%, 0);
    width: 6.8rem;
    height: 8.5rem;
  }
  .btn-wrap{
    position: absolute;
    left: 50%;
    bottom: 0.56rem;
    transform: translate(-50%, 0);
  }
`;

export const Header = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 0.3rem;
  width: 7.5rem;
  height: 0.96rem;
  ${flex['flex-r-sb']}
  font-size: 0.24rem;
  color: #fff;
  z-index: 10;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  transition: .2s;
  &.ac{
    color: #3D3D3D;
    background-color: #F9F9F9;
    border-bottom: 1px solid #D8D8D8;
  }
  img{
    margin-right: 0.26rem;
    width: 0.52rem;
    height: 0.52rem;
  }
`;

export const HeaderLogo = styled.div`
  ${flex['flex-r-fs']}
`;

export const Title = styled.div`
  line-height: 0.72rem;
  font-size: 0.48rem;
  color: #000;
  text-align: center;
`;
export const Group1Wrap = styled.div`
  margin: 0.84rem 0 0.42rem;
`;
export const Group2Wrap = styled.div`
  margin: 0.96rem 0 1.08rem;
`;
export const Info = styled.div`
  line-height: 0.42rem;
  font-size: 0.24rem;
  color: #6D7278;
  text-align: center;
`;

export const PageContainer2 = styled.div`
  ${page}
  ${flex['flex-c-c']}
  background-color: #fff;
`;
export const PageContainer3 = styled.div`
  ${page}
  ${flex['flex-c-c']}
  background-color: #F6F6F6;
`;
