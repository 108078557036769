import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../components/button'
import { Container, FixedWrap, InnerWrap, BtnWrap, Qrcode } from './style'
import {AndroidDownLoadUrl, IosDownLoadUrl} from "../../config";
import { isIos } from '../../utils/index'

function Head(props) {
  const { opacity } = props;

  const downloadAndroid = () => {
    window.location.href = AndroidDownLoadUrl
  };

  const downloadIos = () => {
    if (!isIos()) return;
    window.location.href = IosDownLoadUrl;
  };

  return (
    <Container>
      <div className="block" />
      <FixedWrap opacity={opacity}>
        <InnerWrap>
          <div className="logo-wrap">
            <img src={require('../../assets/images/logo.png')} alt="键多多"/>
            <h1>键多多输入法</h1>
          </div>
          <BtnWrap opacity={opacity}>
            {
              opacity ? (
                <>
                  <Button size="mini" handleClick={downloadAndroid}>安卓下载</Button>
                  <Button color="#000" size="mini" handleClick={downloadIos}>AppStore</Button>
                </>
              ) : null
            }

          </BtnWrap>

          <Qrcode>
            <img src={require('../../assets/images/qrcode.png')} />
            <p>扫码直接下载</p>
          </Qrcode>
        </InnerWrap>
      </FixedWrap>
    </Container>
  )
}

Head.defaultProps = {
  opacity: 0
}
Head.propTypes = {
  opacity: PropTypes.number
}

export default React.memo(Head)
