import React, { useEffect, useState } from 'react'
import HeadBar from '../../../../components/head'
import Banner from '../../../../components/banner'
import PicGroup1 from '../../../../components/picGroup1'
import PicGroup2 from '../../../../components/picGroup2'
import Foot from '../../../../components/foot'
import Button from '../../../../components/button'
import { Container, Content, InnerWrap, Info, BtnWrap, ArrowWrap } from './style'
import { homeData } from '../../data.js'
import { isMac } from '../../../../utils/index'
import { AndroidDownLoadUrl, IosDownLoadUrl } from '../../../../config/index'

function Pc(props) {
  const [banners, setBanners] = useState([]);
  const [opacity, setOpacity] = useState(0);
  const [showArrow, setShowArrow] = useState(true);

  useEffect(() => {
    setBanners(homeData.banners)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', function(e) {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop < 100) {
        setOpacity(scrollTop/100);
      } else {
        if (scrollTop > 800) {
          setShowArrow(false)
        } else {
          setShowArrow(true)
        }
        setOpacity(1);
      }
    })
  }, []);

  const downloadAndroid = () => {
    window.location.href = AndroidDownLoadUrl
  };

  const downloadIos = () => {
    if (isMac) {
      window.location.href = 'https://apps.apple.com/cn/app/id1479144747';
    }
  };

  return (
    <Container>
      <HeadBar opacity={opacity} />
      <Banner banners={banners} />
      <Content>
        <InnerWrap>
          <BtnWrap>
            <Button type="pc" prefix={require('../../../../assets/images/logos/android.png')} handleClick={downloadAndroid}>Android下载</Button>
            <Button type="pc" prefix={require('../../../../assets/images/logos/ios.png')} color="#000" handleClick={downloadIos}>iOS下载</Button>
          </BtnWrap>
          <div className="wrap">
            <PicGroup1 />
            <Info>
              { homeData.content.group1.h.map((item, i) => <h2 key={i}>{item}</h2>) }
              <div className="white-block" />
              { homeData.content.group1.p.map((item, i) => <p key={i}>{item}</p>) }
            </Info>
          </div>
          <div className="wrap">
            <Info>
              { homeData.content.group2.h.map((item, i) => <h2 key={i}>{item}</h2>) }
              <div className="white-block" />
              { homeData.content.group2.p.map((item, i) => <p key={i}>{item}</p>) }
            </Info>
            <PicGroup2 size="normal" />
          </div>
        </InnerWrap>
      </Content>
      <Foot />
      <ArrowWrap>
        {
          showArrow ? <img src={require('../../../../assets/images/next.png')}/> : null
        }
      </ArrowWrap>

    </Container>
  )
}

export default React.memo(Pc)
