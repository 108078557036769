import styled, {css} from 'styled-components'
import style from '../../assets/style/global-style'
import flex from '../../assets/style/flex'

export const Container = styled.div`
  width: 100%;
  height: 76px;
  background-color: #F6F6F6;
  .block{
    width: 100%;
    height: 76px;
    background-color: #F6F6F6;
  }
`;
export const FixedWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 76px;
  background-color: rgba(246, 246, 246, ${props => props.opacity});
  z-index: 10;
  ${props => props.opacity >= 1 && css`
    border-bottom: 1px solid #D8D8D8; 
  `}
`;
export const InnerWrap = styled.div`
  padding: 0 165px;
  height: 76px;
  ${flex['flex-r-sb']}
  ${style.maxWidth}
  .logo-wrap{
    ${flex['flex-r-fs']}
    img{
      margin-right: 10px;
      width: 36px;
      height: 36px;
    }
    h1{
      font-size: 24px;
      color: #3D3D3D;
    }
  }
`;

export const BtnWrap = styled.div`
  ${flex['flex-r-c']}
  opacity: ${props => props.opacity};
  & div:first-child{
    margin-right: 20px;
  }
`

export const Qrcode = styled.div`
  box-sizing: border-box;
  ${flex['flex-c-sb']}
  position: absolute;
  right: 165px;
  top: 103px;
  padding: 12px 0;
  width: 140px;
  height: 172px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid rgba(246, 246, 246, 1);
  img{
    width: 118px;
    height: 118px;
  }
  p{
    font-size: 16px;
    color: #3D3D3D;
  }
`;
