import React, {useEffect, useState} from 'react'
import Swiper from 'swiper'
import { Container } from './style'
import { Header, HeaderLogo } from './pages/style'
import Button from '../../../../components/button/index'
import Page1 from './pages/page1'
import Page2 from './pages/page2'
import Page3 from './pages/page3'
import {downloadApp} from "../../../../utils";
import Toast from "../../../../components/toast";
import { AndroidDownLoadUrl, IosDownLoadUrl } from '../../../../config/index'

function Mobile(props) {
  const [index, setIndex] = useState(0);
  const [didCal, setDidCal] = useState(false);
  // rem
  useEffect(() => {
    let docEl = document.documentElement,
      resizeEvt = 'onorientationchange' in window ? 'onorientationchange' : 'resize',
      recalc = function () {
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        if(clientWidth>=750){
          docEl.style.fontSize = '100px';
        }else{
          docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
        }
        setDidCal(true)
      };
    if (!document.addEventListener) return;
    window.addEventListener(resizeEvt, recalc, false);
    recalc();
    return () => {
      window.removeEventListener(resizeEvt, recalc, false);
      document.removeEventListener('DOMContentLoaded', recalc, false);
    };
  }, []);

  useEffect(() => {
    new Swiper('#swiperContainer', {
      direction: 'vertical',
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      on: {
        activeIndexChange: function() {
          setIndex(this.activeIndex)
        },
      }
    })
  }, []);

  const download = async () => {
    try {
      downloadApp({
        androidDownloadUrl: AndroidDownLoadUrl,
        iosDownLoadUrl: IosDownLoadUrl,
      });
    } catch (e) {
      Toast.error('请在浏览器中打开')
    }
  };

  return (
    <Container index={index} className="swiper-container" id="swiperContainer">
      {
        didCal ? (
          <Header className={`${index > 0 ? 'ac' : ''}`}>
            <HeaderLogo>
              <img src={require('../../../../assets/images/logo_sq.png')} />
              <p>键多多输入法</p>
            </HeaderLogo>
            <Button type="mobile" size="mini" handleClick={download}>立即下载</Button>
          </Header>
        ) : null
      }
      <div className="swiper-wrapper">
        <div className="swiper-slide"><Page1 active={index === 0} /></div>
        <div className="swiper-slide"><Page2 active={index === 1} /></div>
        <div className="swiper-slide">{ didCal ? <Page3 active={index === 2} /> : null }</div>
      </div>
      <div className="swiper-pagination"></div>
    </Container>
  )
}

export default React.memo(Mobile)
