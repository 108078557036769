import styled from 'styled-components'

export const Container = styled.div`
  height: 560px;
  background-color: #999;
  --swiper-pagination-color: #24AF61;
  .banner-slide{
    img{
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`;

export const Cover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.cover});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  &::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  }
`;
