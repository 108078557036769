import styled, { keyframes, css } from 'styled-components'

const updown2 = keyframes`
  from{
    transform: translateY(-10px);
  }
  to{
    transform: translateY(10px);
  }
`;

export const Container = styled.div`
  position: relative;
  width: 569px;
  height: 488px;
  ${props => props.size === 'mini' && css`
    width: 6.16rem;
    height: 5.32rem;
  `}
  .absolute{
    position: absolute;
  }
  .bg{
    ${props => props.size === 'mini' 
      ? css`
        width: 5.78rem;
        height: 5.32rem;
      ` 
      : css `
        width: 531px;
        height: 488px;
      `
    }
  }
  .e1{
    ${props => props.size === 'mini'
      ? css`
        left: 1.96rem;
        top: -0.04rem;
        width: 1.12rem;
        height: 1.08rem;
      `
      : css `
        left: 181px;
        top: -5px;
        width: 102px;
        height: 101px;
      `
    }
    animation: ${updown2} 2s cubic-bezier(0.45, 0.07, 0.55, 0.93) 0.1s infinite alternate;
  }
  .e2{
    ${props => props.size === 'mini'
      ? css`
        right: -0.2rem;
        top: 0.9rem;
        width: 1rem;
        height: 1.1rem;
      `
      : css `
        right: -15px;
        top: 80px;
        width: 96px;
        height: 100px;
      `
    }
    animation: ${updown2} 2s cubic-bezier(0.45, 0.07, 0.55, 0.93) 0.6s infinite alternate;
  }
  .e3{
    ${props => props.size === 'mini'
      ? css`
        right: 0.74rem;
        bottom: 0.36rem;
        width: 0.9rem;
        height: 0.9rem;
      `
      : css `
        right: 71px;
        bottom: 31px;
        width: 78px;
        height: 80px;
      `
    }
    animation: ${updown2} 2s cubic-bezier(0.45, 0.07, 0.55, 0.93) 0.2s infinite alternate;
  }
  .e4{
    
    ${props => props.size === 'mini'
      ? css`
        left: 0.46rem;
        bottom: 1.26rem;
        width: 0.82rem;
        height: 0.86rem;
      `
      : css `
        left: 25px;
        bottom: 96px;
        width: 108px;
        height: 114px;
      `
    }
    animation: ${updown2} 2s cubic-bezier(0.45, 0.07, 0.55, 0.93) 0.5s infinite alternate;
  }
`;
