import React, {useEffect} from 'react'
import Swiper from 'swiper'
import { Container } from './style'
import 'swiper/css/swiper.min.css'

function PicGroup2(props) {
  const { size } = props;

  useEffect(() => {
    var mySwiper = new Swiper('#groupContainer', {
      autoplay: true,
      duration: 1000,
      slidesPerView: 1.66,
      effect: 'coverflow',
      coverflowEffect: {
        rotate:0,//滑动时旋转角度
        stretch:0,//聚合宽度
        depth: 1000,//景深
        modifier:1,//覆盖叠加层数
        slideShadows: false,
      },
      spaceBetween: -30,
      centeredSlides: true,
      loop: true,
    })
    return () => {
      mySwiper.destroy();
    }
  }, [size]);

  return (
    <Container size={size}>
      <div className="swiper-container" id="groupContainer" >
        <div className="swiper-wrapper">
          <div className="slide swiper-slide">
            <img className="k1" src={require('../../assets/images/group2/k1.png')} />
          </div>
          <div className="slide swiper-slide">
            <img className="k1" src={require('../../assets/images/group2/k2.png')} />
          </div>
          <div className="slide swiper-slide">
            <img className="k1" src={require('../../assets/images/group2/k3.png')} />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default React.memo(PicGroup2)
