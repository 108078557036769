import React, {useEffect} from 'react'
import Swiper from 'swiper'
import { Container, Cover } from './style'

function Banner(props) {
  const { banners=[] } = props;

  useEffect(() => {
    if (banners.length > 0) {
      new Swiper('#bannerContainer', {
        // loop: true,
        // autoplay: true,
        // duration: 2000,
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true
        // },
      })
    }
  }, [banners]);

  return (
    <Container className="swiper-container" id="bannerContainer">
      <div className="swiper-wrapper">
        {
          banners.map((item, index) => {
            return (
              <div className="banner-slide swiper-slide" key={index}>
                <Cover cover={item.cover} />
              </div>
            )
          })
        }
      </div>
      {/*<div className="swiper-pagination"></div>*/}
    </Container>
  )
}

export default React.memo(Banner)
