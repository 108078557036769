import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './style'

function PicGroup1(props) {
  const { size } = props;
  return (
    <Container size={size}>
      <img className={`absolute bg`} src={require('../../assets/images/group1/bg.png')} />
      <img className={`absolute e1`} src={require('../../assets/images/group1/e1.png')} />
      <img className={`absolute e2`} src={require('../../assets/images/group1/e2.png')} />
      <img className={`absolute e3`} src={require('../../assets/images/group1/e3.png')} />
      <img className={`absolute e4`} src={require('../../assets/images/group1/e4.png')} />
    </Container>
  )
}

PicGroup1.defaultProps = {
  size: 'normal'
};

PicGroup1.propTypes = {
  size: PropTypes.string,
};

export default React.memo(PicGroup1)
