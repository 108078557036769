import React from 'react'
import PicGroup from '../../../../../components/picGroup1/index'
import { PageContainer2, Title, Group1Wrap, Info } from './style'
import { homeData } from '../../../data'

function Page2(props) {
  const { active } = props;
  return (
    <PageContainer2>
      <Title>
        { homeData.content.group1.h.map((item, i) => <h2 style={{animationDelay: `0.${i}s`}} className={`animated ${active ? 'fadeInDown' : ''}`} key={i}>{item}</h2>) }
      </Title>
      <Group1Wrap>
        <PicGroup size="mini" />
      </Group1Wrap>
      <Info>
        { homeData.content.group1.p.map((item, i) => <p style={{animationDelay: `0.${i+1}s`}} className={`animated ${active ? 'fadeInUp' : ''}`} key={i}>{item}</p>) }
      </Info>
    </PageContainer2>
  )
}

export default React.memo(Page2)
