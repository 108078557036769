export const isM = () => {
  let ua = navigator.userAgent;
  return ua.indexOf('Android') > -1 || ua.indexOf('iPhone') > -1 || ua.indexOf('iPod') > -1 || ua.indexOf('Symbian') > -1
};
export const isAndroid = () => {
  const u = navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
};
export const isIos = () => {
  const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};
export const isMac = () => {
  return /macintosh/i.test(navigator.userAgent);
}
export const isWx = () => {
  const ua = navigator.userAgent.toLowerCase();
  return !!ua.match(/micromessenger/i)
};
export const isQQ = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf(' qq') > -1;
};
export const downloadApp = (params={}) => {
  const { androidDownloadUrl='', iosDownLoadUrl='' } = params;
  if (isIos()) {
    // window.location.href = `https://itunes.apple.com/cn/app/${appStoreCode}`;
    window.location.href = iosDownLoadUrl;
    return Promise.resolve();
  } else if (isWx() || isQQ()) {
    return Promise.reject();
  } else {
    window.location.href = androidDownloadUrl;
    return Promise.resolve();
  }
};

